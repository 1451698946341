import * as React from 'react'
import ReactHtmlParser from 'react-html-parser'
import { Works } from '../../interface'
import { scrollHandler } from '../../utils/functions'
import Image from '../../components/image'


class WorksDetail extends React.Component<{data?:Works}> {

	works:any = {};
  isModalOpen:Boolean = false;

  constructor(props:any) {
    super(props);        

    if(this.props.data)
    this.works = this.props.data;

  }

	render(){
    
    let images = this.works.acf.works_images;
    let pages = [];
    let thumbnails = [];
    let coverImageElem;
    let pageBody;

    if(images.length){

      let classNames = 'page post-cover col-' + images[0].works_images_image_number_of_columns;
      let classNames2 = 'image col-' + images[0].works_images_image_number_of_columns;
      let coverImage = images[0].works_images_image.url.replace('/yjrimsk/image/upload/', '/yjrimsk/image/upload/q_auto/');

      coverImageElem = 
        <div className={classNames} data-id="image-1">
          <div className="inner">
            <div className={classNames2}>
              <img src={coverImage} alt=""/>
              <div className="image-cover"></div>
            </div>
            <div className="caption" dangerouslySetInnerHTML={{ __html: images[0].works_images_image_caption }} />
          </div>
        </div>

      for(var i = 1; i <images.length; i++){
        let classNames = 'image col-' + images[i].works_images_image_number_of_columns
        let dataID = 'image-' + (i + 1)
        let image = images[i].works_images_image.url.replace('/yjrimsk/image/upload/', '/yjrimsk/image/upload/q_auto/')
        pages.push(
          <div className="page" data-id={dataID} key={i}>
            <div className="inner">
              <div className={classNames}>
                <img src={image} alt=""/>
                <div className="image-cover"></div>
              </div>
              <div className="caption" dangerouslySetInnerHTML={{ __html: images[i].works_images_image_caption }} />
            </div>
          </div>
        );
      }     

      for(var i = 0; i < images.length; i++){
        let imageID = 'image-' + (i + 1)
        let image = images[i].works_images_image.url.replace('/yjrimsk/image/upload/', '/yjrimsk/image/upload/q_auto/')
        thumbnails.push(
          <li key={i}>
            <div className="thumbnail-wrap" data-id={imageID} onClick={(e:any)=>{
              this.onThumbnailClicked(e);
            }}>
              <img src={image} alt=""/>
            </div>
          </li>
        );
      }         

    } else {
      let featuredImage = this.works.featured_image_src.replace('/yjrimsk/image/upload/', '/yjrimsk/image/upload/q_auto/')
      coverImageElem = <img src={featuredImage} alt=""/>
    }

    if(this.works.content){
      pageBody = <div className="page post-body">
        <div className="inner">
          <div className="image" dangerouslySetInnerHTML={{ __html: this.works.content }} />
          <div className="caption">Statement</div>
        </div>
      </div>
    }

    let subtitle = this.works.acf.subtitle ? '<br />' + this.works.acf.subtitle : '';
    let thumbnailsStyle = this.works.acf.subtitle ? 'thumbnails has-subtitle' : 'thumbnails';

		return (
      <React.Fragment>
        <span className="toggle-thumbnail-gallery page-menu-button">Grid View</span>
        <section className="contents-section">
          <div className="container">      
            <h1 className="page-title">
              {this.works.title}{ReactHtmlParser(subtitle)}
            </h1>
            <div className="page-contents">
              <div className="pages">
                {coverImageElem}  
                {pageBody}       
                {pages}
              </div>
            </div>
          </div>
        </section>        
        <div className="modal-contents-layer">          
          <div className={thumbnailsStyle}>
            <ul>
              {thumbnails}
            </ul>
          </div>
        </div>
        <span className="close-button close-gallery-button"><Image src="icon_close_01.svg" alt="close" /></span>
      </React.Fragment>
    )
  }

  lockBackground = () => {
    this.isModalOpen = !this.isModalOpen;
    if (this.isModalOpen) {
      let offsetY = window.pageYOffset;
      document.body.style.top = `${-offsetY}px`;
      document.body.classList.add('no-scroll');
    } else {
      let offsetY:any = document.body.style.top;
      offsetY = Number(offsetY.replace('px', ''));
      offsetY = Math.abs(parseInt(offsetY || 0, 10));
      document.body.classList.remove('no-scroll');
      document.body.style.top = '0';
      window.scrollTo(0, offsetY);
    }
  }


  componentDidMount() {

    let tnButton = document.getElementsByClassName('toggle-thumbnail-gallery')[0];
    let closeButton = document.getElementsByClassName('close-gallery-button')[0];
    let modal = document.getElementsByClassName('modal-contents-layer')[0];
    tnButton.addEventListener("click", (e)=>{
      e.preventDefault();
      modal.classList.add('fade-in');
      modal.classList.remove('fade-out');
      document.body.classList.add('grid-view-mode');
      this.lockBackground();
    }, {passive: false} );

    closeButton.addEventListener("click", (e)=>{
      e.preventDefault();
      modal.classList.remove('fade-in');
      modal.classList.add('fade-out');
      document.body.classList.remove('grid-view-mode');
      this.lockBackground();      
    }, {passive: false} );    


    //window.addEventListener('scroll', func, {passive: true});
    
    let el = document;
    let event = document.createEvent('HTMLEvents');
    event.initEvent('scroll', true, false);
    el.dispatchEvent(event);  

    let vh = window.innerHeight * 0.01;
	  document.documentElement.style.setProperty('--vh', `${vh}px`);	
    
    scrollHandler();
  }

  onThumbnailClicked(e:any){
    let tnID = e.currentTarget.getAttribute('data-id');
  
    let tgt = document.querySelectorAll('.pages [data-id="'+tnID+'"]');
    
    let modal = document.getElementsByClassName('modal-contents-layer')[0];
    modal.classList.add('fade-out');
    modal.classList.remove('fade-in');
    document.body.classList.remove('no-scroll');
    document.body.classList.remove('grid-view-mode');
    let rect = tgt[0].getBoundingClientRect();
    let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    let tgtY = rect.top + scrollTop;

    this.lockBackground();
    window.scrollTo(0, tgtY);

    
    
  }

}


export default WorksDetail
