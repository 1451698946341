import * as React from 'react'
//import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Layout from '../../components/layout'
import WorksDetail from '../../components/works/WorksDetail'
import { Works } from '../../interface'
import SEO from '../../components/seo'


type Props = {
  items: Works[]
  pathname: string,
  data: any
}


class WorksDetailPage extends React.Component<Props> {

  data:any;
  // static async getInitialProps({ pathname }:Props) {
  //   const items: Works[] = await wpFetchWrapper(
  //     'http://test2.teafac.com/wp/wp-json/wp/v2/works/'
  //   )

  //   return { items, pathname }
  // }

  constructor(props: Props) {
    super(props);
	
    // this.title = props.title ? props.title + ' - ' : '';	
    // this.children = props.children;

  }


  render() {
    return <Layout>
      <SEO title={this.props.data.wordpressWpWorks.title} />
      {this.props.data.wordpressWpWorks && <WorksDetail data={this.props.data.wordpressWpWorks} />}
    </Layout>
  
  }
}

export default WorksDetailPage


export const query = graphql`
      query($slug: String!) { 
        __typename
        wordpressWpWorks(slug: { eq: $slug }) {
          content
          featured_image_src
          title
          acf {
            subtitle
            works_images {
              works_images_image {
                url
              }
              works_images_image_caption
              works_images_image_number_of_columns
            }
          }
        }        
      }

    `;
